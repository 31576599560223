<template>
  <div style="width:100%">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6">
          <v-card :color="colores.primario" dark>
            <v-card-text class="text-center">
              <v-row no-gutters>
                <v-col cols="12" md="6" align-self="center"><span class="white--text">ESCALAMIENTOS</span></v-col>
                <v-col cols="12" md="6"><v-avatar v-if="items.items_correos.correoslog.length>0" size="30" :color="colores.secundario" class="white--text">{{ items.items_correos.correoslog.length}}</v-avatar></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card :color="colores.secundario" dark>
            <v-card-text class="text-center">
              <v-row no-gutters>
                <v-col cols="12" md="6" align-self="center"><span class="white--text">OTROS</span></v-col>
                <v-col cols="12" md="6"><v-avatar v-if="items.items_correos.correosotro.length>0" size="30" class="white--text" :color="colores.primario">{{ items.items_correos.correosotro.length}}</v-avatar></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-list>
          <div v-for="(item, index) in items.items_correos.correoslog" :key="index">
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar size="30" class="white--text" :color="colores.primario"> {{ index + 1 }} </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row>
                    <v-col cols="12" md="6">{{ item.gestion.codigo }}</v-col>
                    <v-col cols="12" md="6">{{ item.escaladoa.usuario}}</v-col>
                  </v-row>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.descripcion }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
        <v-list>
          <div v-for="(item, index) in items.items_correos.correosotro" :key="index">
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar size="30" class="white--text" :color="colores.secundario"> {{ index + 1 }} </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row>
                    <v-col cols="12" md="6">{{ item.gestion.codigo }}</v-col>
                    <v-col cols="12" md="6">{{ item.correo}}</v-col>
                  </v-row>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.descripcion }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
      </v-list>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'bandeja_correosComponent',
  components: {
  },
  props: ['colores', 'items'],
  data: () => ({
    tab: null
  }),
  created () {

  },
  methods: {

  }
}
</script>
